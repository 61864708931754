// BOOTSTRAP CORE COMPONENTS
import Button from './bs/button';
import Dropdown from './bs/dropdown';
import Collapse from './bs/collapse';
import Offcanvas from './bs/offcanvas';
import Carousel from './bs/carousel';
import Popover from './bs/popover';
import Tab from './bs/tab';

// MDB COMPONENTS
import Stepper from './additional/stepper';

window.Button = Button;
window.Dropdown = Dropdown;
window.Carousel = Carousel;
window.Collapse = Collapse;
window.Offcanvas = Offcanvas;
window.Popover = Popover;
window.Tab = Tab;
window.Stepper = Stepper;


